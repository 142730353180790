import { useState } from 'react'

import { Button, Icon } from 'components'
import { ReactComponent as ShareIcon } from 'assets/svg/shareBox.svg'
import { ReactComponent as MenuIcon } from 'assets/svg/menuIcon.svg'
import { ReactComponent as CloseIcon } from 'assets/svg/close.svg'

import { maskedDateTime } from 'utilities/date'
import {
  useGetTacticalDetails,
  useResumeTacticalDuty,
} from 'services/displacementMap'
import { handleStateRender } from '../../../../utils/handleStateRender'
import { occurrenceTypesLabel } from '../../../../types'

import styles from './TacticalDetails.module.scss'
import { DropDown, BackDrop, Loader } from '../../../../components'
import { useDisplacementModal, useMap } from '../../../../contexts'
import usePostIntervention from 'shared/hooks/services/occurrence/usePostIntervention'
import { useUserInfo } from 'shared/hooks'
import { usePhoneCall } from 'services/ura/call'

interface TacticalDetailsProps {
  onClose: () => void
  tacticalId: string
}

export const TacticalDetails = ({
  onClose,
  tacticalId,
}: TacticalDetailsProps) => {
  const { userInfo } = useUserInfo()

  const [modalIsVisible, setModalIsVisible] = useState(false)
  const { handleOpenModal } = useDisplacementModal()
  const { selectedRoute, handleRoute } = useMap()
  const { mutate: call } = usePhoneCall()
  const { mutateCreateIntervention } = usePostIntervention({
    occurrenceId: selectedRoute.occurrence?.id,
  })
  const { data, isFetched } = useGetTacticalDetails(tacticalId)

  const { mutate } = useResumeTacticalDuty(selectedRoute.tactical?.id)

  const isPausedTactical = data?.status === 'PAUSED'

  const modalItems = [
    {
      label: 'Trocar viatura',
      onClick: () => {
        onClose()
        handleOpenModal('CHANGE_CAR')
      },
    },
    {
      label: 'Encerrar plantão',
      onClick: () => {
        onClose()
        handleOpenModal('TERMINATE_DUTY')
      },
    },
    {
      label: isPausedTactical ? 'Retomar plantão' : 'Pausar plantão',
      onClick: () => {
        if (isPausedTactical) {
          const userId = userInfo.id
          mutate(
            { userId },
            {
              onSuccess: () => {
                handleOpenModal('RESUME_SUCCESS_DUTY')
                onClose()
              },
              onError: () => {
                handleOpenModal('RESUME_ERROR_DUTY')
                onClose()
              },
            },
          )
        } else {
          handleOpenModal('PAUSE_DUTY')
          onClose()
        }
      },
    },
  ]

  return (
    <BackDrop onClose={onClose}>
      <main className={styles.container}>
        {
          {
            view: !!data && (
              <>
                <header>
                  <h1>{data.agent.name}</h1>
                  <h2>Veículo {data.vehicle.licensePlate}</h2>
                  <div className={styles.actions}>
                    <button
                      type="button"
                      onClick={() => setModalIsVisible(true)}
                    >
                      <MenuIcon />
                    </button>
                    <button type="button" onClick={onClose}>
                      <CloseIcon />
                    </button>
                  </div>
                  {modalIsVisible && (
                    <DropDown
                      items={modalItems}
                      onClose={() => setModalIsVisible(false)}
                      className={styles.dropDown}
                    />
                  )}
                </header>
                <hr />
                <div className={styles.content}>
                  <section>
                    <div>
                      <h4>Início de turno</h4>
                      <span>{maskedDateTime(data.startDate)}</span>
                    </div>
                    <div>
                      <h4>Rastreador</h4>
                      {data.tracker && (
                        <span>
                          {data.tracker?.serial} - {data.tracker?.model}
                        </span>
                      )}
                    </div>
                    <div>
                      <h4>Velocidade</h4>
                      <span>{data.speed}km/h</span>
                    </div>
                    <div>
                      <h4>Última atualização</h4>
                      <span>{maskedDateTime(data.lastPositionDate)}</span>
                    </div>
                    <div>
                      <h4>Último movimento</h4>
                      <span>{maskedDateTime(data.lastMovementDate)}</span>
                    </div>
                    {!!data.occurrencesOnService?.length && (
                      <div>
                        <h4>Ocorrências em atendimento</h4>
                        <ul>
                          {data.occurrencesOnService.map((occurrence) => (
                            <li
                              key={occurrence.id}
                              className={styles[occurrence.typeName]}
                              onDoubleClick={() =>
                                handleRoute({
                                  type: 'occurrence',
                                  marker: occurrence,
                                })
                              }
                            >
                              <div>
                                <span>
                                  {[
                                    occurrenceTypesLabel[occurrence.typeName],
                                    occurrence.title,
                                  ]
                                    .filter(Boolean)
                                    .join(' - ')}
                                </span>
                                <span>
                                  {occurrence.accountCode}
                                  {' - '}
                                  {occurrence.customerName}
                                </span>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}

                    <div>
                      <button
                        type="button"
                        className={styles.ButtonLink}
                        onClick={() => handleOpenModal('LOG_HISTORY')}
                      >
                        <ShareIcon />
                        Ver mais
                      </button>
                    </div>
                  </section>
                  <hr />
                </div>
                <section>
                  <Button
                    type="primary"
                    buttonTitle="Tático"
                    onClick={() => {
                      const userId = userInfo.id

                      if (userId) {
                        const phoneFormatted = `${
                          data.phone.provinceCode || ''
                        }${data.phone.number}`

                        mutateCreateIntervention(
                          {
                            userId,
                            typeName: 'PHONE_CALL',
                            attributes: {
                              number: phoneFormatted,
                              status: 'PENDING',
                            },
                            title: `Ligação para o tático ${data.agent.name}`,
                            tags: [{ name: 'SAFE_CALL' }],
                          },
                          {
                            onSuccess: () =>
                              call({
                                phoneNumber: data.phone.number.toString(),
                                ramal: userInfo?.phoneExtension || '',
                              }),
                          },
                        )
                      }
                    }}
                  >
                    <Icon name="phone" />
                  </Button>
                </section>
              </>
            ),
            loading: (
              <>
                <div className={styles.actions}>
                  <button type="button" onClick={onClose}>
                    <CloseIcon />
                  </button>
                </div>
                <Loader />
              </>
            ),
            error: (
              <>
                <div className={styles.actions}>
                  <button type="button" onClick={onClose}>
                    <CloseIcon />
                  </button>
                </div>
                <span className={styles.error}>
                  Erro ao carregar ocorrência. Tente novamente.
                </span>
              </>
            ),
          }[handleStateRender(data, isFetched)]
        }
      </main>
    </BackDrop>
  )
}
