import {
  useDisplacementModal,
  useMap,
} from 'domains/occurrence/screens/DisplacementMap/contexts'

import { Icon } from 'components'
import { TacticalMarker } from 'services/displacementMap/types'
import { icons } from 'domains/occurrence/screens/DisplacementMap/containers/Map/components/Marker/utils'
import { DropDown } from 'domains/occurrence/components'
import { useUserInfo } from 'shared/hooks'
import {
  useGetTacticalDetails,
  useResumeTacticalDuty,
} from 'services/displacementMap'

import styles from './styles.module.scss'
import usePostIntervention from 'shared/hooks/services/occurrence/usePostIntervention'
import { formatLicensePlate } from 'domains/vehicles/utilities/masks'
import { usePhoneCall } from 'services/ura/call'

type TacticalItemProps = TacticalMarker & {
  onViewTactical: () => void
}

const TacticalItem = (props: TacticalItemProps) => {
  const {
    id,
    agent,
    color,
    vehicle,
    status = 'COMMUNICATION_OFF',
    alert = false,
    onViewTactical,
  } = props

  const { userInfo } = useUserInfo()

  const { handleOpenModal, handleCloseModal } = useDisplacementModal()
  const { selectedRoute, handleRoute } = useMap()
  const { mutate: call } = usePhoneCall()
  const { mutate } = useResumeTacticalDuty(id)
  const { data } = useGetTacticalDetails(selectedRoute.tactical?.id)
  const { mutateCreateIntervention } = usePostIntervention({
    occurrenceId: selectedRoute.occurrence?.id,
  })

  const isPausedTactical = status === 'PAUSED'

  const menuItems = [
    {
      label: 'Trocar viatura',
      onClick: () => {
        handleOpenModal('CHANGE_CAR')
      },
    },
    {
      label: 'Encerrar plantão',
      onClick: () => {
        handleOpenModal('TERMINATE_DUTY')
      },
    },
    {
      label: isPausedTactical ? 'Retomar plantão' : 'Pausar plantão',
      onClick: () => {
        if (isPausedTactical) {
          const userId = userInfo.id
          mutate(
            { userId },
            {
              onSuccess: () => {
                handleOpenModal('RESUME_SUCCESS_DUTY')
              },
              onError: () => {
                handleOpenModal('RESUME_ERROR_DUTY')
              },
            },
          )
        } else {
          handleOpenModal('PAUSE_DUTY')
        }
      },
    },
    {
      label: 'Ligar para o tático',
      onClick: () => {
        const userId = userInfo.id

        if (userId && data) {
          const phoneFormatted = `${
            data.phone.provinceCode || ''
          }${data.phone.number}`

          mutateCreateIntervention(
            {
              userId,
              typeName: 'PHONE_CALL',
              attributes: {
                number: phoneFormatted,
                status: 'PENDING',
              },
              title: `Ligação para o tático ${data.agent.name}`,
              tags: [{ name: 'SAFE_CALL' }],
            },
            {
              onSuccess: () => {
                call({
                  phoneNumber: data.phone.number.toString(),
                  ramal: userInfo?.phoneExtension || '',
                })

                handleCloseModal()
              },
            },
          )
        }
      },
    },
    {
      label: 'Ver histórico',
      onClick: () => {
        handleOpenModal('LOG_HISTORY')
      },
    },
  ]

  return (
    <div className={styles.container}>
      <div
        className={[
          styles.leftSection,
          status === 'PAUSED' && styles.paused,
        ].join(' ')}
      >
        <div
          style={{
            background: color ?? '#656975',
          }}
          className={[
            styles.content,
            status && styles[status],
            status && styles.status,
            alert && styles.alert,
          ].join(' ')}
        >
          <div
            style={{
              background: color ?? '#656975',
            }}
            className={styles.icon}
          >
            {icons[vehicle.category]}
          </div>
        </div>

        <div className={styles.owner}>
          <span>{agent.name}</span>
          <small>{formatLicensePlate(vehicle?.licensePlate)}</small>
        </div>
      </div>

      <div className={styles.actions}>
        <button type="button" onClick={onViewTactical}>
          <Icon name="view" width={20} height={20} />
        </button>

        <DropDown
          trigger={
            <button className={styles.menu} type="button">
              <Icon name="menu-kebab" width={18} height={18} />
            </button>
          }
        >
          {menuItems.map(({ onClick, label }) => (
            <button
              key={label}
              className={styles.menuItem}
              onClick={() => {
                handleRoute({ marker: props, type: 'tactical' })

                onClick()
              }}
            >
              {label}
            </button>
          ))}
        </DropDown>
      </div>
    </div>
  )
}

export default TacticalItem
